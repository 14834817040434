import { useNuxtApp } from "#app";
import { FieldInfoInterface } from "../lib/types";

export const getFieldLabel = (fieldInfo: FieldInfoInterface): string => {
  const { $i18n } = useNuxtApp();

  if (!fieldInfo.meta.translations.length) return fieldInfo.name;

  const translation = fieldInfo.meta.translations.find(
    (translation) => translation.language === $i18n.locale.value,
  );

  return translation === undefined ? fieldInfo.name : translation.translation;
};

